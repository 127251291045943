// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import type { Node } from 'react'

import Textarea from 'react-textarea-autosize'

import { SimpleBlock, Header } from '../../Block'
import Settings from '../../Settings'
import { SETTINGS_CORPORATE_SAVE_CONFIG } from '../../SettingsCorporate/SettingsCorporate.actionTypes'
import ValidationText from '../../ValidationText'

import styles from './Invitation.module.scss'

const MAX_INVITATION_LENGTH = 1024

type Props = {
  onSubmit: Object => void,
  ukConfig: Object,
  working: boolean,
}

const Invitation = (props: Props): Node => {
  const { working, ukConfig } = props
  const dispatch = useDispatch()

  const { t } = useTranslation('Settings')

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      invitation: ukConfig.invitation_text_for_dwellers,
    },
    validationSchema: Yup.object({
      invitation: Yup.string()
        .max(
          MAX_INVITATION_LENGTH,
          t('InvitationMaxLength', { maxLength: MAX_INVITATION_LENGTH })
        )
        .required(t('InvitationTextRequired')),
    }),
    onSubmit: values => {
      dispatch({
        type: SETTINGS_CORPORATE_SAVE_CONFIG,
        params: {
          invitation_text_for_dwellers: values.invitation,
        },
      })
    },
  })

  return (
    <SimpleBlock working={working}>
      <Header header>{t('InvitationSettingTitle')}</Header>
      <form onSubmit={formik.handleSubmit}>
        <Settings.Row label={t('InvitationText')}>
          <Textarea
            className={styles.textarea}
            name='invitation'
            value={formik.values.invitation}
            onChange={formik.handleChange}
          />
        </Settings.Row>
        <Settings.Row>
          <ValidationText
            active={formik.errors.invitation}
            text={formik.errors.invitation}
          />
        </Settings.Row>
        <Settings.Actions
          withChanges={formik.dirty}
          isValid={formik.isValid}
          working={working}
          onCancel={formik.handleReset}
        />
      </form>
    </SimpleBlock>
  )
}

export default Invitation
